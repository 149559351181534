@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [data-theme="light"] {
    --color-primary: #121212;
    --color-secondary: #9a9a9a;
    --color-invert: #ffffff;
    --color-grey: #cecece;
    --opacity-base: 75, 75, 75;

    --color-bg-primary: #ffffff;
    --color-bg-secondary: #ededed;
    --color-bg-invert: #121212;
    --toastify-toast-width: unset;
  }

  [data-theme="night"] {
    --color-primary: #ffffff;
    --color-secondary: #9a9a9a;
    --color-invert: #121212;
    --color-grey: #555555;
    --opacity-base: 255, 255, 255;

    --color-bg-primary: #121212;
    --color-bg-secondary: #353535;
    --color-bg-invert: #ffffff;
    --toastify-toast-width: unset;
  }

  [data-theme="endemic"] {
    --color-primary: #ffffff;
    --color-secondary: rgba(255, 255, 255, 0.7);
    --color-invert: #121212;
    --color-grey: rgba(255, 255, 255, 0.5);
    --opacity-base: 255, 255, 255;

    --color-bg-primary: #ffffff;
    --color-bg-secondary: #ededed;
    --color-bg-invert: #121212;
    --toastify-toast-width: unset;
  }

  [data-theme="endemic"]>.bg-primary,
  [data-theme="endemic"]>.bg-primary .navigation.bg-primary,
  [data-theme="endemic"]>.bg-primary .header {
    background: linear-gradient(180deg,
        #00a09a 0px,
        #d60a51 5000px,
        #f9b233 10000px,
        #d60a51 15000px,
        #00a09a 20000px);
    background-repeat: repeat-y;
  }

  .text-yellow.gradient {
    background: linear-gradient(90deg, #f9b233 0%, #f09000 92.34%);
  }

  .text-green.gradient {
    background: linear-gradient(90deg, #00a09a 0%, #00817c 56.84%);
  }

  .text-red.gradient {
    background: linear-gradient(90deg, #d60a51 0%, #b50a45 93.44%);
  }

  .text-endemic.gradient {
    background: linear-gradient(90deg, #d60a51 0%, #f9b233 50%, #00817c 100%);
  }

  .text-endemic.gradient:hover {
    background: linear-gradient(90deg, #d60a51 0%, #f9b233 25%, #00817c 50%, #f9b233 75%, #d60a51 100%);
    background-size: 200% 100%;
    background-repeat: repeat;
    -webkit-animation: AnimateGradientText 5s linear infinite;
    -moz-animation: AnimateGradientText 5s linear infinite;
    animation: AnimateGradientText 5s linear infinite;
  }

  .text-yellow.gradient,
  .text-green.gradient,
  .text-red.gradient,
  .text-endemic.gradient,
  .text-endemic.gradient:hover {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  [data-theme="endemic"] .text-yellow.gradient,
  [data-theme="endemic"] .text-green.gradient,
  [data-theme="endemic"] .text-red.gradient,
  [data-theme="endemic"] .text-endemic.gradient,
  [data-theme="endemic"] .text-endemic.gradient:hover {
    background: inherit;
    color: white;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: inherit;
    background-clip: inherit;
    text-fill-color: inherit;
  }

  h1 {
    @apply text-h1-sm;
  }

  h2 {
    @apply text-h2-sm;
  }

  h3 {
    @apply text-h3-sm;
  }

  input {
    @apply text-body-3;
  }

  small {
    @apply text-body-4;
  }

  button {
    @apply text-button;
  }

  /* ---- VENDOR ---- */

  .react-datepicker__arrow {
    visibility: hidden;
  }

  .react-datepicker-popper .react-datepicker {
    border-radius: 10px;
    @apply py-2;
    @apply font-endemic !important;
    @apply font-light;
    @apply border-endemicGray-300;
  }

  .react-datepicker .react-datepicker__header {
    background: white;
    border-bottom: none;
  }

  .react-datepicker .react-datepicker__current-month {
    @apply mb-4;
  }

  .react-datepicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    @apply mb-2;
  }

  .react-datepicker .react-datepicker__navigation {
    top: 14px;
  }

  .react-datepicker .react-datepicker__day--outside-month {
    @apply text-endemicGray-500;
  }

  .react-datepicker .react-datepicker__day-names {
    @apply font-normal;
  }

  .react-datepicker .react-datepicker__day--selected,
  .react-datepicker .react-datepicker__day--in-selecting-range,
  .react-datepicker .react-datepicker__day--in-range,
  .react-datepicker .react-datepicker__month-text--selected,
  .react-datepicker .react-datepicker__month-text--in-selecting-range,
  .react-datepicker .react-datepicker__month-text--in-range,
  .react-datepicker .react-datepicker__quarter-text--selected,
  .react-datepicker .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker .react-datepicker__quarter-text--in-range,
  .react-datepicker .react-datepicker__year-text--selected,
  .react-datepicker .react-datepicker__year-text--in-selecting-range,
  .react-datepicker .react-datepicker__year-text--in-range {
    @apply bg-green;
    @apply rounded-full;
  }

  .react-datepicker .react-datepicker__day-name,
  .react-datepicker .react-datepicker__day,
  .react-datepicker .react-datepicker__time-name {
    @apply w-7;
  }

  .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    @apply py-3;
    @apply px-5;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  .react-datepicker .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    @apply rounded-full;
  }

  .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    @apply bg-green;
  }

  .react-datepicker .react-datepicker__day--selected:hover,
  .react-datepicker .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker .react-datepicker__day--in-range:hover,
  .react-datepicker .react-datepicker__month-text--selected:hover,
  .react-datepicker .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker .react-datepicker__month-text--in-range:hover,
  .react-datepicker .react-datepicker__quarter-text--selected:hover,
  .react-datepicker .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker .react-datepicker__year-text--selected:hover,
  .react-datepicker .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker .react-datepicker__year-text--in-range:hover {
    @apply bg-green;
  }

  @media screen and (min-width: 1024px) {
    h1 {
      @apply text-h1;
    }

    h2 {
      @apply text-h2;
    }

    h3 {
      @apply text-h3;
    }
  }

  @-webkit-keyframes AnimateGradientText {
    0% {
      background-position: 0% 0%
    }

    100% {
      background-position: 200% 0%
    }
  }

  @-moz-keyframes AnimateGradientText {
    0% {
      background-position: 0% 0%
    }

    100% {
      background-position: 200% 0%
    }
  }

  @keyframes AnimateGradientText {
    0% {
      background-position: 0% 0%
    }

    100% {
      background-position: 200% 0%
    }
  }
}

/*
  FIX to prevent default autocomplete styles on input
  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
input:-webkit-autofill:active {
  transition:
    background-color 5000s ease-in-out 0s,
    color 5000s ease-in-out 0s;
}

/* -----VENDOR STYLES-----*/

.recharts-tooltip-wrapper {
  color: #121212;
}

/* -----VENDOR STYLES ---- DATEPICKER -----*/
/* TODO: move vendor style to specific file  */

.react-datepicker-popper {
  width: 400px;
  height: 297px;
}

.react-datepicker__tab-loop {
  position: relative;
}

.react-datepicker-wrapper {
  display: block !important;
  font-family: var(--endemic-museo) !important;
}

.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
}

.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -2px;
  border-bottom-color: #dadada;
}

.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: white;
  z-index: 1;
}

.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker__tab-loop .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-width: 9px !important;
}

.react-datepicker-wrapper .react-datepicker__close-icon {
  margin-top: 4px;
}

.react-datepicker-wrapper .react-datepicker__close-icon:after {
  background-color: #dadada;
}

.react-datepicker__triangle {
  transform: translateX(32px) !important;
}

.rhap_progress-bar .rhap_progress-filled,
.rhap_progress-bar .rhap_progress-indicator {
  background-color: #d60a51;
}

.rhap_container {
  outline: none;
}

.rhap_controls-section {
  flex: 0 1 auto;
}

.rhap_time {
  color: #fff;
}

.Toastify__toast-theme--light {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--endemic-museo);
  background-color: black;
  color: white;
}

.Toastify__toast-theme--dark {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--endemic-museo);
  background-color: white;
  color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Customize website's scrollbar like Mac OS */
textarea,
.custom-scroll {
  scrollbar-gutter: stable;
}

textarea::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
}

textarea::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

textarea::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  border: 0px solid #fff;
}

textarea::-webkit-scrollbar-button,
.custom-scroll::-webkit-scrollbar-button {
  display: block;
  height: 4px;
}

textarea:hover::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  background-clip: content-box;
  border: 4px solid transparent;
}

textarea::-webkit-scrollbar-thumb:hover,
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  background-clip: content-box;
  border: 4px solid transparent;
}

.padding-inherit {
  padding: inherit;
}

.thin-custom-scroll::-webkit-scrollbar-thumb {
  border: none;
  background: #121212;
  border-radius: 4px;
}

[data-theme="night"] .thin-custom-scroll::-webkit-scrollbar-thumb {
  background: #fff;
}

.thin-custom-scroll::-webkit-scrollbar-thumb:hover {
  border: none;
}

.thin-custom-scroll::-webkit-scrollbar {
  width: 2px;
  background: #dadada;
  border-radius: 4px;
}

[data-theme="night"] .thin-custom-scroll::-webkit-scrollbar {
  background: #9a9a9a;
}

.thin-custom-scroll::-webkit-scrollbar-button {
  display: none;
}

/* Quill */
.quill {
  border-radius: 20px;
}

.ql-editor {
  min-height: 200px;
  height: max-content;
  font-family: var(--museo-font) !important;
}

.quill.quill-custom-border .ql-toolbar,
.quill.quill-custom-border .ql-container {
  border-color: #d60a51;
}

[data-theme="endemic"] .quill.quill-custom-border .ql-toolbar,
[data-theme="endemic"] .quill.quill-custom-border .ql-container {
  border-color: #f9b233;
}

.ql-editor-display {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: 80px;
}

.ql-editor blockquote {
  border-radius: 8px;
  border-left: 4px solid #00a09a !important;
  padding: 24px 40px !important;
  line-height: 1.5;
}

.ql-editor blockquote,
.ql-editor-display blockquote,
.ql-editor img,
.ql-editor-display img {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ql-editor blockquote.forum-post-reply {
  font-size: 16px;
}

.ql-editor blockquote.forum-post-reply blockquote {
  border-left: 2px solid #cecece !important;
  background: transparent;
  padding: 24px !important;
  border-radius: 0;
}

.ql-editor a {
  color: #d60a51 !important;
  text-decoration: none;
}

.ql-snow .ql-editor,
.ql-editor-display {
  font-size: 16px;
  font-weight: 300;
}

.ql-editor-display .ql-size-small,
.ql-snow .ql-editor .ql-size-small {
  font-size: 12px;
  font-weight: 300;
}

.ql-editor-display .ql-size-large,
.ql-snow .ql-editor .ql-size-large {
  font-size: 18px;
  font-weight: 300;
}

.ql-editor-display .ql-size-huge,
.ql-snow .ql-editor .ql-size-huge {
  font-size: 32px;
  font-weight: 300;
}

[data-theme="endemic"] .ql-editor a {
  color: #fff !important;
}

.quill-container {
  padding: 0 !important;
}

[data-theme="light"] .ql-editor blockquote {
  background: #f3f3f3;
  color: #121212;
}

[data-theme="night"] .ql-editor blockquote,
[data-theme="endemic"] .ql-editor blockquote {
  border: solid 1px white !important;
}

.pie {
  --color: #D60A51;
  --color-yellow: #F9B233;
}

.pie:before {
  background:
    conic-gradient(var(--color) calc(var(--percentage)*1%), #0000 0);
}

[data-theme="endemic"] .profileDropdown .pie:before {
  background: 
    conic-gradient(var(--color-yellow) calc(var(--percentage)*1%), #0000 0);
}
